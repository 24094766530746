import React, { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
// import s from './NavBar.module.css'

import "./NavBar.css"

import withSettings from '../../hoc/withSettings'
import { compose, isPending } from '@reduxjs/toolkit'
import { GetIsAdmin } from '../../pages/TodoPage'
import { useDispatch } from 'react-redux'
import { postTableThunk } from '../../redux/tableSlice'
import { TableApiType } from '../../types/types'



// export const getTeamName = () => {
//     const params = useParams()
//     let team  = params.team as string
//     team = '/' + team.toLowerCase()
// return team
// }



const MenuItem = (props:{
    team :string,
    addrss: string,
    style: string,
    name: string,

}) => {
  return (
    <NavLink  
    to={props.team + "/" + props.addrss}>

{
    ({isActive}) =>(

        <div className=
        {isActive ? 'mainMenu mainMenu-active' : 'mainMenu'}
        >

        <div className={"mainMenu-Icon mainMenu-Icon-" + props.style}></div>
        <span className="mainMenu-Text">{props.name}</span>
        
    </div>

    )
    }
</NavLink>
  )
}




function Navbar({dictFunc}:any) {

    let dispatch:any = useDispatch()
  
    const [oldPath, setOldPath] = useState('')

    const isAdmin = GetIsAdmin()

    const params = useParams()
    let team  = params.team as string
    team = '/' + team.toLowerCase()
    let currentAdress:string = window.location.href 

    let postTableObj:TableApiType = {
        path:'appstat/visits', 
        rowContent: {
            team:team.substring(1), 
            address:currentAdress}}



    if (oldPath===currentAdress){

    } else {

        setOldPath(currentAdress)
        dispatch(postTableThunk(postTableObj))
    }
  

    //     isPending ? "pending" : isActive ? "mainMenu2_hover" : ""
    // }
    // >
    //     <div className="mainMenu-Icon mainMenu-Icon-management"></div>
    //     <span className="mainMenu-Text">Управление</span>
    
        

    return (
        <div className="wrapper">
{isAdmin==1 &&
<MenuItem 
    team={team}    
    addrss={"management"} 
    style={'management'} 
    name={'Управление'}
/>
}

<MenuItem 
    team={team} 
    addrss={"tasks"} 
    style={'task'} 
    name={'Задачи'}
/>

<MenuItem 
    team={team} 
    addrss={"transmital"} 
    style={'transmittal'} 
    name={'Трансмиттал'}
/>
<MenuItem 
    team={team} 
    addrss={"drc"} 
    style={'revision'} 
    name={'Ревизии'}
/>
<MenuItem 
    team={team} 
    addrss={"mom"} 
    style={'mom'} 
    name={'Протоколы'}
/>





{isAdmin===0 
            ? ( 

<MenuItem 
    team={team} 
    addrss={"translator"} 
    style={'translator'} 
    name={'Переводчик'}
/>

            )
            : (
<MenuItem 
    team={team} 
    addrss={"translatorpro"} 
    style={'translator'} 
    name={'Переводчик'}
/>
        )
}

<MenuItem 
    team={team} 
    addrss={"mdr"} 
    style={'mdr'} 
    name={'Эмдиар'}
/>

<MenuItem 
    team={team} 
    addrss={"documents/inbox"} 
    style={'document'} 
    name={'Документ'}
/>
<MenuItem 
    team={team} 
    addrss={"reports"} 
    style={'report'} 
    name={'Отчеты'}
/>
{isAdmin===1 && 
<MenuItem 
    team={team} 
    addrss={"email"} 
    style={'email'} 
    name={'Почта'}
/>
}




        </div>
    )
}




export default compose (
    withSettings
  )(Navbar)

